import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import "../components/i18n"

const NotFoundPage = () => {
  const { t, i18n } = useTranslation()
  return (
    <Layout title={t("title")}>
      <SEO title={t("notFound.title")} />
      <h1>{t("notFound.title")}</h1>
      <p>{t("notFound.p1")}</p>
    </Layout>
  )
}

export default NotFoundPage
